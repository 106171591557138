.head-box {
  padding: 40px 0 70px;
  h1 {
    .purple {
      color: #be01ff;
    }
  }
}

.title {
  color: #452B29;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 8px;
}

.desc {
  color: #383838;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  a {
    font-weight: 500;
    text-decoration: none;
  }
}

.text-required {
  color: #778293;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}

.logo-box {
  img {
    float: right;
    min-height: 400px;
    max-width: 100%;
    margin-top: -50px;
  }
}

.text-box.ant-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
