@media screen and (max-width: 991px) {
  body {
    .head-box {
      position: relative;
      padding: 20px 0 20px;
    }
    .box h2 {
      font-size: 18px;
    }
    .logo-box {
      position: absolute;
      opacity: 0.2;
      top: 0;
      bottom: 50px;
      right: 0;
      left: 0;
      text-align: center;
      img {
        float: none;
        max-height: 100%;
        margin: 0;
      }
    }
    .menu {
      flex-direction: column;
    }
    .ant-layout-header {
      margin: 30px 0;
    }
  }
}

@media screen and (max-width: 767px) {
  body {
    .title {
      font-size: 28px;
    }
    .desc {
      font-size: 20px;
      margin-bottom: 16px;
    }
    .text-required {
      font-size: 18px;
    }
    .select-statement .label,.vacancy-wrap h3,.index__formBtn {
      font-size: 18px;
    }
    .index__formBtn {
      width: 100%;
    }
    .vacancy-wrap {
      font-size: 16px;
    }
    h1 {
      font-size: 40px;
    }
    h2 {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }
  .row,.menu2 {
    flex-direction: column;
    align-self: flex-start;
    align-items: flex-start;
  }
  .menu2 {
    margin-bottom: 10px;
    li {
      margin-bottom: 5px;
    }
  }
  .social li {
    margin: 30px 15px 0;
  }
  .index__formInput {
    min-height: 44px;
  }
  .index__formWrapper .index__error {
    font-size: 12px;
  }
  .index__formDateSelect {
    input {
      width: 60px;
      text-align: center;
    }

    & > div {
      min-width: 135px;
    }
  }
  .no-match,.error-box {
    h1 {
      font-size: 28px;
    }
  }
  body .ant-radio-group .ant-radio-button-wrapper {
    font-size: 16px;
    font-weight: normal;
    padding: 16px 6px;
  }
  .checkbox-block {
    .rc-checkbox {
      float: left;
    }
    .checkbox-label {
      display: block;
      padding-left: 20px;
    }
  }
}

@media screen and (max-width: 540px) {
  .file-wrap {
    padding-bottom: 35px;
  }

  // .index__fileButtonGroup {
  //   position: absolute;
  //   bottom: -100%;
  //   left: 0;
  //   padding: 0;
  // }

  .index__fileControls {
    margin-top: 0;
    min-height: 44px;
    height: 44px;
    background: #f5f5f5;
    border-color: #f5f5f5;
    & > div {
      margin-right: 0;
    }
    .index__fileInput + label {
      min-height: 44px;
      line-height: 44px;
      width: 48px;
    }
  }

}

@media screen and (max-width: 319px) {
  body {
    .index__formDateSelect {
      & > input, & > div {
        min-width: auto;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 280px) {
  body {
    .index__formDateSelect {
      display: block;

      & > input, & > div {
        width: 100%;
        min-width: auto;
        margin-bottom: 20px;
      }
    }
  }
}
