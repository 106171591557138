body {
  h1 {
    color: #383838;
    font-size: 28px;
    line-height: 32px;
    font-style: normal;
    font-weight: 400;
    strong {
      font-weight: 500;
    }
  }

  h2 {
    margin-bottom: 50px;
    font-weight: 500;
    font-size: 40px;
    line-height: 110%;
    text-transform: uppercase;
    color: #000;
    small {
      display: block;
      font-size: 18px;
      font-weight: normal;
      text-transform: initial;
      line-height: normal;
      margin-top: 10px;
    }
  }
}
