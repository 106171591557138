body {
  .site-layout-content {
    padding: 0;
  }

  .layout {
    overflow-x: hidden;
  }

  .ant-layout-header {
    margin: 30px 0 -30px;
  }

  .ant-layout-header {
    padding: 0 24px;
  }

  .ant-layout-footer {
    padding: 24px;
  }

  .flex-col {
    display: flex;
    & > div {
      display: flex;
      width: 100%;
      & > div {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 0!important;
      }
    }
  }
}

.logo {
  a {
    display: inline-block;
    width: 246px;
    height: 50px;
    img {
      background-size: contain;
      height: 100%;
    }
  }
}

.container {
  max-width: 1100px;
  margin:  0 auto;
}

.box {
  padding: 40px 0;
  &.last-box {
    padding: 40px 0 20px;
  }
  &.first-box {
    padding: 0 0 60px;
  }
  &.small-box {
    padding: 47px 0;
  }
  h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 16px;
  }
}

.select-statement {
  .label {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
}

.help-box {
  padding-bottom: 47px;
}

.gray-box {
  position: relative;
  &:before {
    display: block;
    content: '';
    position: absolute;
    height: 100%;
    min-width: 100vw;
    width: 4000px;
    top: 0;
    left: -1000px;
    background: #F3F3F3;
  }
}

.load-box {
  .ant-spin {
    width: 100%;
    margin-top: 50px;
  }
  .ant-spin-dot-item {
    background: #452B29;
  }
}

.form-box {
  margin-bottom: 50px;
}

.wrap-career {
  .form-box {
    margin-top: -80px;
  }
}

.position {
  font-size: 22px;
  font-weight: 500;
  color: #383838;
}
.error-box {
  margin-top: 50px;
}
.no-match,.error-box {
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 50px;

    small {
      font-size: 18px;
      font-weight: normal;
    }
  }
}

.wrap-sidebar > .ant-spin {
  margin-top: 50px;
}

.vacancy-wrap {
  padding: 60px 0 0;
  h3 {
    color: #383838;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  color: #383838;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  b {
    margin-bottom: 16px;
    display: block;
  }
}

.success-wrap {
  height: 100%;
  text-align: center;
  padding-top: 100px;
}
