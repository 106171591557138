@import 'fonts.less';
@import 'layout.less';
@import 'input.less';
@import 'typography.less';
@import 'header';
@import 'footer';
@import 'response';

body {
  font-family: 'Graphik LCG', sans-serif;
}

#root > .layout {
  min-height: 100vh;
}

.ant-layout-content {
  margin: 24px;
}

.logo {
  float: left;
  color: #fff;
}

.site-layout-content {
  background: #fff;
  padding: 24px;
  min-height: 200px;
}

.ant-menu .ant-menu-item a {
  margin: 8px;
  margin-top: 9px;
}

label.index__formLabelRequired {
  display: block;
}

.form-extra {
  float: right;
}

#ot-sdk-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}

@font-family: Arial, sans-serif;@layout-body-background: #fff;@layout-header-background: transparent;@btn-primary-bg: #000;@btn-primary-color: #fff;@link-color: #452B29;@input-border-color: #F3F3F3;@border-radius-base: 0;@text-color: #383838;@layout-footer-background: #000;@input-height-base: 52px;