.index {
  &__formWrapper {
    font-family: 'Graphik LCG', sans-serif;
    // .ant-col {
    //   & > div {
    //     & > div {
    //       margin-bottom: 30px!important;
    //     }
    //   }
    // }
    .index__error {
      margin: 0;
      position: absolute;
      font-size: 13px;
    }

    .checkbox-block {
      .rc-checkbox {
        margin-top: 3px;
      }
    }

    .form-extra {
      margin-top: 8px;
    }

    &.jobot-form-server-failed button[type=submit] {
      color: #fff;
      background: #ed0214;
    }
  }

  &__formInput {
    border-color: #F3F3F3;
    background: #F3F3F3;
    border-radius: 0;
    min-height: 52px;
  }

  &__formDateSelect {
    & > div {
      width: 100%;

      & + input {
        margin-right: 0;
      }
    }
  }

  &__formLabelRequired {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    &:before {
      content: none;
    }
  }
  &__formBtn {
    background: #452B29;
    font-weight: 600;
    font-size: 22px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    padding: 0 80px;
    min-height: 52px;
    transition: all .3s ease-out;
    &:hover:not([disabled]) {
      background: #B25A3D;
    }
  }
  &__fileList {
    margin: 0;
    border: 0;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    padding-right: 80px;
  }
  &__fileItem {
    padding: 11px 0 11px 40px;
    & > div:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media screen and (max-width: 767px) {
      padding: 6px 0 6px 16px;
    }
  }
  &__fileButtonGroup {
    padding-left: 10px;
    .index__downloadButton {
      display: none;
    }
  }
  &__fileControls {
    background: #f5f5f5;
    display: flex;
    position: relative;
    height: 56px;
    border: 1px solid #f5f5f5;
    border-radius: 0;
    min-height: 52px;
    padding: 8px 10px;
    z-index: 0;
    .index__fileInput {
      & + label {
        background: #452B29;
        font-weight: 600;
        font-size: 22px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        min-height: 52px;
        line-height: 52px;
        width: 54px;
        padding: 0;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-out;      
        &:hover {
          background: #B25A3D;
        }
        &:before {
          content: '';
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAwCAYAAABjezibAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKMSURBVHgB7ZnxrdsgEMbxBtmgjOAN6m7gDZwNXjYwG7xs4LdB2wnoBq+dgLdBusEVZCohcmfubLD6Rz/pFCk5Pn7GB9ikU5UFABf/Mfr47GPwcYkR9BHjm4/vXdd9qLMUwHzMPh7Al/XRq9bynYxCsFxLHPkmcDPUkfOhc/9OHVCA8x+G+PmHj18+fqq17sIIfVJrXY5Em5D3pUptboyc9TEU2mofhmj/fvh2b8AZoY+OtzbXq9qrWnAMSPnsrg2X+PaIp5WaNIFL/LGa5NVia7jYR1jo87X0xmk4SeBgXbS/wlpXIWzwUAz5vLesj6XUYOLCwVrsFmg5QBbizOOat6kJ56CskHPZ6DOfLI8acKF23oEvswGo82TqKrCN/00AF9qHWfkCzyP7EIwgtIBzkNQaNipA1KL/fsi9dsHFfGrkNJLrsryB8MzXQrsXbiHgeiQXG8Fe8S76vjUD0aeKHXC5tyPgBsR3UCfDBd0JQIdeCDzfdwruFXBhcBcCLnynkfwZyZ3SBFOAmwm4KwFXnN0F7+cygHWb4Rq0hAsaFEeN4V4Ib6M42oC7EfkSuKkVnCHyFySXmt2TxPs/XJLbE953LpyoaEG2Loq2UqwzLYSbifxrdbhoYv9lOKw2qP1yFMBpAk78vnvPDBwQ7w2AL8Y3As4hcPJzF8CewbbzU0jTFC4a5rdhYLQJkGNzuGiaS6sd2oBzu+GicT6C4lOlApxWR4QYl89DzoKLHeSzmL0MNIeLnQxIBzOjneix/iiklUDGkWM//9UAxEYRIsQE626jY94M+A7hmsAlkAb2yzWFOwjpToFLIK/AO+sLsqfCJZA6glIHRAtwXw0PqvhXGKzb1N/4fepfqF5/AHPSsOgJIihoAAAAAElFTkSuQmCC');
          position: absolute;
          width: 20px;
          height: 24px;
          background-size: 20px;
        }
        .button-text {
          display: none;
        }
      }
    }
  }
}

.index__fileControls .index__fileInput:disabled + label:before {
  display: none;
}

.index__formWrapper .jobot-form-submit-failed .jobot-form-invalid {
  .index__formInput {
    border-color: #ed0214;
  }
  .jobot-forms__control, .jobot-forms-rc-select-selector {
    border-color: #ed0214;
  }
  .rc-tree-select.rc-tree-select .rc-tree-select-selector {
    border-color: #ed0214;
  }
}

.site-layout-content {
  padding: 0;
}

label,body .ant-form-item-label > label {
  height: auto;
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

body {
  .select-input {
    padding: 0px;
  }
  .jobot-forms__control {
    border-color: #000;
    border-radius: 0;
    min-height: 52px;

    &.jobot-forms__control--is-focused {
      border-color: #000;
      box-shadow: none;
    }
    .jobot-forms__indicator-separator {
      display: none;
    }
  }

  .jobot-forms-rc-select.jobot-forms-rc-select-single {
    background-color: #fff;
    line-height: 32px;
    cursor: default;

    .jobot-forms-rc-select-selector {
      border-color: #000;
      border-radius: 0;
    }

    .jobot-forms-rc-select-selection-search-input {
      cursor: default;
    }

    .jobot-forms-rc-select-clear {
      top: 0;
      height: 100%;
      line-height: 52px;
    }
  }

  .jobot-forms-rc-select-dropdown {
    position: relative;
    border-radius: 0;
    border-color: #000;
    z-index: 10;

    .jobot-forms-rc-select-item, .jobot-forms-rc-select-selection-search {
      cursor: pointer;
    }

    .jobot-forms-rc-select-item {
      padding: 8px 16px;
    }

    .jobot-forms-rc-select-item-option-active {
      background-color: #ccc;
    }
    .jobot-forms-rc-select-item-option-selected {
      background-color: #000;
    }
  }

  .jobot-forms__menu {
    border-radius: 0;
    padding: 0;
    top: inherit;
    z-index: 2;

    & > div {
      min-height: 74px;
    }
  }
  .jobot-forms__option--is-selected {
    background-color: #000;
    color: #fff;
  }
  .jobot-forms__option--is-focused {
    background-color: #ccc;
  }
  .jobot-forms__option {
    &:active {
      background: #ccc;
    }
  }
  .rc-checkbox:hover .rc-checkbox-inner, .rc-checkbox-input:focus + .rc-checkbox-inner {
    border-color: #452B29;
  }
  .rc-tree-select.rc-tree-select-single {
    &.rc-tree-select-show-arrow .rc-tree-select-selection-item {
      cursor: pointer;
    }
    &.rc-tree-select-show-arrow.rc-tree-select-open .rc-tree-select-selection-item,
    &.rc-tree-select-show-arrow.rc-tree-select-open input.rc-tree-select-selection-search-input {
      cursor: text;
    }
    .rc-tree-select-selector:before {
      display: none;
    }
    .rc-tree-select-selector, .rc-tree-select-selection-search .rc-tree-select-selection-search-input {
      height: 52px;
    }
    .rc-tree-select-selector {
      border: 1px solid #000;
      border-radius: 0;
    }
    &:hover .rc-tree-select-selector, &.rc-tree-select-focused .rc-tree-select-selector {
      box-shadow: none !important;
      border-color: #000;
    }
    .rc-tree-select-selector .rc-tree-select-selection-item {
      line-height: 50px;
    }
  }
  .checkbox-wrapper .checkbox-label {
    font-weight: normal;
    padding-top: 5px;
  }
  .checkbox-wrapper-required {
    &:before {
      display: none;
    }
  }
  .checkbox-wrapper .rc-checkbox-checked .rc-checkbox-inner {
    background-color: #452B29;
  }
  .checkbox-wrapper .rc-checkbox-checked .rc-checkbox-inner,.rc-checkbox-inner {
    border-color: #452B29;
    width: 20px;
    height: 20px;
    border-radius: 0;
    &:after {
      left: 7px;
      top: 3px;
      width: 6px;
      height: 10px;
    }
  }
  .ant-radio-group {
    width: 100%;
    overflow: hidden;
    margin-top: 10px;

    .ant-radio-button-wrapper {
      border-color: #452B29;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        color: #452B29;
      }

      &:before {
        background-color: #452B29;
      }

      &.ant-radio-button-wrapper-checked, &.ant-radio-button-wrapper-checked:first-child {
        border-color: #452B29;
        border-right-color: #452B29;

        &:before {
          background-color: #452B29;
        }
      }

      &.ant-radio-button-wrapper-checked {
        background-color: #f6ece8;
        color: #B25A3D;
      }

      position: relative;
      padding: 20px;
      display: table-cell;
      width: 50%;
      text-align: center;
      vertical-align: middle;
      color: #452B29;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: normal;

      span + span {
        vertical-align: middle;
      }
    }
  }
  .rc-tree-select-dropdown {
    border-radius: 0;
    border-color: #000;
    padding: 0;
  }

  .rc-tree-select-tree-treenode-selected.rc-tree-select-tree-treenode-leaf-last {
    position: relative;
  }

  .rc-tree-select-tree {
    .rc-tree-select-tree-node-content-wrapper {
      min-height: 32px;
      display: block;
      width: 100%;
      transition: none;
      &:hover {
        &:before {
          content: '';
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          position: absolute;
          background-color: #fff;
        }
      }
    }
    .rc-tree-select-tree-treenode.rc-tree-select-tree-treenode-selected {
      background-color: #000000;
    }
    .rc-tree-select-tree-node-content-wrapper.rc-tree-select-tree-node-selected {
      background-color: transparent;
    }
    .rc-tree-select-tree-treenode {
      position: relative;
      width: 100%;
      line-height: 32px;
    }
    .rc-tree-select-tree-switcher {
      line-height: 32px;
      position: relative;
      z-index: 9;
    }
    .rc-tree-select-tree-title {
      position: relative;
      padding: 6px 0 2px;
      display: inline-block;
      font-weight: 440;
    }
    .rc-tree-select-tree-node-selected:before {
      content: '';
      width: 100%;
      position: absolute;
      background: #000!important;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  .rc-tree-select-single .rc-tree-select-selector .rc-tree-select-selection-item, .rc-tree-select-single .rc-tree-select-selector .rc-tree-select-selection-placeholder {
    line-height: 50px;
  }
  .file-wrap {
    position: relative;
  }
}

.input-text {
  margin-top: 40px;
  .ant-row {
    .ant-form-item-label {
      padding: 0;
    }
    .ant-form-item-control-input {
      min-height: auto;
      input {
        border: 0;
        background: transparent;
        padding: 0;
        color: #000;
        font-size: 18px;
      }
    }
  }
}

.maskFormInput {
    width: 100%;
    font-size: inherit;
    padding: 8px 10px;
    border-radius: 4px;
    border: 1px solid #ccc;

    border-color: #000;
    border-radius: 0;
    min-height: 52px;
}
.maskFormInput:focus {
    outline: none;
}

.long-name-fix {
  margin-top: -29px;
}
@media screen and (max-width: 991px) {
  .long-name-fix {
    margin-top: 0px;
  }
}

body {
  .ant-input[disabled],.ant-input[disabled]:hover {
    border-color: #f5f5f5;
  }
  .gray-box {
    .ant-input[disabled],.ant-input[disabled]:hover,.input.index__formInput {
      background: #fff;
      border-color: #fff;
    }
  }
}

